import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e65e2c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-section" }
const _hoisted_2 = {
  class: "form-section bordered",
  style: {"align-items":"center"}
}
const _hoisted_3 = { class: "input-form" }
const _hoisted_4 = {
  class: "form-section bordered",
  style: {"align-items":"center"}
}
const _hoisted_5 = { class: "input-form" }
const _hoisted_6 = {
  class: "form-section bordered",
  style: {"align-items":"center"}
}
const _hoisted_7 = { class: "input-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item_picker = _resolveComponent("item-picker")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    full: "",
    heading: _ctx.getTranslation('core.domain.inHouseRepairs'),
    padded: "",
    title: _ctx.getTranslation('core.domain.inHouseRepairs')
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_smart_trak_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_button, {
            disabled: !_ctx.state.repairBeingWorkedOn.quantity,
            variant: "primary",
            onClick: _ctx.submitInHouseRepair
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.submit')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "4",
            "cols-offset": "4"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.domain.item')), 1),
                _createVNode(_component_item_picker, {
                  key: _ctx.state.componentKey,
                  "item-type": _ctx.ItemType.IN_HOUSE_REPAIRS,
                  "include-only-repairable-items": "",
                  "search-only": "",
                  placeholder: _ctx.getTranslation('core.common.typeToSearchForItem'),
                  onOnClear: _ctx.clearForm,
                  onOnSelect: _ctx.selectItem
                }, null, 8, ["item-type", "placeholder", "onOnClear", "onOnSelect"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.systemRepairBalance')), 1),
                _createElementVNode("p", _hoisted_3, _toDisplayString(`${_ctx.getTitleCaseTranslation('core.domain.onHandInventory')}: ${_ctx.state.itemRepairSystemCount ?? ''}`), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.repairDetails')), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_b_row, { class: "input-row" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_col, { cols: "3" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.repairType')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_b_col, { cols: "9" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_select, {
                            modelValue: _ctx.state.repairBeingWorkedOn.repairId,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.repairBeingWorkedOn.repairId) = $event)),
                            options: _ctx.state.availableRepairsForItem,
                            "text-field": "name",
                            "value-field": "id",
                            onChange: _ctx.populateRepairParts
                          }, null, 8, ["modelValue", "options", "onChange"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_b_row, { class: "input-row" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_col, { cols: "3" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.quantity')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_b_col, { cols: "9" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_form_input, {
                            modelValue: _ctx.state.repairBeingWorkedOn.quantity,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.repairBeingWorkedOn.quantity) = $event)),
                            "number-options": { allowsNegative: true },
                            type: "number",
                            onChange: _ctx.updateRepairPartQty
                          }, null, 8, ["modelValue", "onChange"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("label", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.repairPartsUsed')), 1),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.repairBeingWorkedOn.completedRepairParts, (repairPartUsed) => {
                    return (_openBlock(), _createBlock(_component_b_row, {
                      key: repairPartUsed.repairPartId,
                      class: "input-row"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_col, { cols: "3" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(repairPartUsed.repairPart.description), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_b_col, { cols: "9" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_form_input, {
                              modelValue: repairPartUsed.quantity,
                              "onUpdate:modelValue": ($event: any) => ((repairPartUsed.quantity) = $event),
                              "number-options": { allowsNegative: true },
                              type: "number"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["heading", "title"]))
}