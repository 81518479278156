import CompletedRepairPart from './CompletedRepairPart';

export default class CompletedRepair {
    public completedRepairParts: CompletedRepairPart[] = [];

    public itemId: number = 0;

    public locationId: number = 0;

    public transactionId?: number;

    public quantity: number = 0;

    public repairId: number = 0;

    public userId: number = 0;

    constructor(init?: Partial<CompletedRepair>) {
        Object.assign(this, init);
    }
}
