
import { defineComponent, reactive } from 'vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import ItemPicker from '@/components/ItemPicker.vue';
import Screen from '@/components/layout/Screen.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import { useNotification } from '@/composable/useNotifications';
import ItemType from '@/domain/enums/ItemType';
import Item from '@/domain/Item';
import CompletedRepair from '@/domain/Repair/CompletedRepair';
import Repair from '@/domain/Repair/Repair';
import InventoryService from '@/services/InventoryService';
import RepairService from '@/services/RepairService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import CoreStore from '@/store/CoreStore';
import useItemRepair from '@/composable/useItemRepair';
import RepairState from '@/interfaces/RepairState';

type State = {
    componentKey: number;
    availableRepairsForItem: Repair[];
    itemRepairSystemCount?: number;
    repairBeingWorkedOn: CompletedRepair;
    selectedItem?: Item;
    standardRepairPartQtyMap: Map<number, number>;
};

export default defineComponent({
    name: 'in-house-repairs',
    components: {
        BButton,
        BCol,
        BFormInput,
        BFormSelect,
        BRow,
        ItemPicker,
        Screen,
        SmartTrakFooter,
    },
    setup() {
        const { inventoryCategoryStore, profileStore } = CoreStore.getInstance();
        const inventoryService = new InventoryService();
        const repairService = new RepairService();
        const { showSuccess } = useNotification();
        const state = reactive<State>({
            componentKey: 0,
            availableRepairsForItem: [],
            repairBeingWorkedOn: new CompletedRepair(),
            standardRepairPartQtyMap: new Map<number, number>(),
        });

        const {
            populateRepairParts,
            submitRepair,
            updateRepairPartQty,
        } = useItemRepair(
            {
                state: (state as unknown) as RepairState,
                userLocationId: profileStore.userLocation.id,
                userId: profileStore.id,
            },
        );

        function clearForm() {
            state.componentKey += 1;
            state.availableRepairsForItem = [];
            state.itemRepairSystemCount = undefined;
            state.repairBeingWorkedOn = new CompletedRepair();
            state.selectedItem = undefined;
        }

        async function setItemRepairSystemCount(selectedItem: Item): Promise<void> {
            const repairInventoryCategory = inventoryCategoryStore.getRepairCategory();

            if (repairInventoryCategory) {
                const groupedInventory = await inventoryService.getItemInventoryGroupedByCategoryForSingleItem(selectedItem.id, profileStore.userLocation.id);

                state.itemRepairSystemCount = groupedInventory?.getInventoryForCategory(repairInventoryCategory);
            }
        }

        async function selectItem(selectedValue: { item: Item }) {
            state.selectedItem = selectedValue.item;

            if (state.selectedItem) {
                const itemsInHouseRepairsPromise = repairService.getItemInHouseRepairs(state.selectedItem.id);

                itemsInHouseRepairsPromise.then((repairs) => {
                    state.availableRepairsForItem = repairs;
                });
                await setItemRepairSystemCount(state.selectedItem);
            }
        }

        async function submitInHouseRepair() {
            const newCompletedRepairId = await submitRepair();

            if (newCompletedRepairId) {
                showSuccess(getTitleCaseTranslation('core.common.repairSubmitted'));
                clearForm();
            }
        }

        return {
            clearForm,
            getTitleCaseTranslation,
            getTranslation,
            ItemType,
            populateRepairParts,
            selectItem,
            state,
            submitInHouseRepair,
            updateRepairPartQty,
        };
    },
});
