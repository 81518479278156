import { computed, ComputedRef } from 'vue';
import RepairState from '@/interfaces/RepairState';
import RepairService from '@/services/RepairService';
import CompletedRepair from '@/domain/Repair/CompletedRepair';
import CompletedRepairPart from '@/domain/Repair/CompletedRepairPart';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

export interface ItemRepairProps {
    state: RepairState;
    userLocationId: number;
    userId: number;
}

export interface UseItemRepair {
    populateRepairParts: (selectedRepairId: number) => void;
    submitRepair: () => Promise<number>;
    updateRepairPartQty: (repairQty: number) => void;
    repairDescription: ComputedRef<string>;
}

export default function useItemRepair({ state, userLocationId, userId }: ItemRepairProps): UseItemRepair {
    const repairService = new RepairService();

    const repairDescription = computed(() => {
        const selectedRepair = state.availableRepairsForItem.find((repair) => repair.id === state.repairBeingWorkedOn.repairId);
        let repairQuantitiesText = '';

        selectedRepair?.repairPartQuantities.forEach((repairPart) => {
            repairQuantitiesText += `${repairPart.repairPart.description} (${repairPart.quantity}x)<br/>`;
        });

        return state.repairBeingWorkedOn.repairId ? `${getTitleCaseTranslation('core.domain.repairParts')}: ${repairQuantitiesText} <br/>
        Directions: ${selectedRepair?.description ?? ''}` : getTranslation('core.domain.selectRepairToContinue');
    });

    function populateRepairParts(selectedRepairId: number) {
        const selectedRepair = state.availableRepairsForItem.find((repair) => repair.id === selectedRepairId);

        if (selectedRepair) {
            state.repairBeingWorkedOn = new CompletedRepair({
                itemId: state.selectedItem?.id,
                locationId: userLocationId,
                repairId: selectedRepair.id,
                userId,
                completedRepairParts: selectedRepair.repairPartQuantities.map(
                    (repairPartQty) => new CompletedRepairPart({
                        repairPart: repairPartQty.repairPart,
                        repairPartId: repairPartQty.repairPartId,
                        repairPartCost: repairPartQty.repairPart.billingPrice,
                    }),
                ),
            });
            state.standardRepairPartQtyMap = new Map<number, number>(selectedRepair.repairPartQuantities.map((repairPartQty) => [repairPartQty.repairPartId, repairPartQty.quantity]));
        }
    }

    async function submitRepair() {
        const newCompletedRepairId = await repairService.submitCompletedRepair(state.repairBeingWorkedOn);

        return newCompletedRepairId;
    }

    function updateRepairPartQty(repairQty: number) {
        state.repairBeingWorkedOn.completedRepairParts.forEach((completedRepairPart) => {
            completedRepairPart.quantity = (state.standardRepairPartQtyMap.get(completedRepairPart.repairPartId) ?? 0) * repairQty;
        });
    }

    return {
        populateRepairParts,
        submitRepair,
        updateRepairPartQty,
        repairDescription,
    };
}
